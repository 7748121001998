const vacanciesData = [
  // {
  //   vacancyType: "Administrative Vacancies",
  //   positions: ["Vice Principal"],
  //   criteria: `The Candidate must
  //     be a Post Graduate with B.Ed/ M.Ed having
  //     10 years of experience as PGT & at least 3
  //     years experience at administrative level in
  //     a reputed CBSE School.
  //     The candidate must have a proven track
  //     record of success to drive curriculum
  //     development, innovative pedagogical
  //     practices, holistic education, staff
  //     enrichment, team building and leadership
  //     qualities with proficiency in computers,
  //     effective communication skills and
  //     knowledge of NEP.`,
  // },

  // {
  //   vacancyType: "Administrative Vacancies",
  //   positions: ["Primary Headmistress"],
  //   criteria:
  //     "The Candidate must be a Post Graduate with 10 years of teaching experience including 3 years experience in Administration in a reputed CBSE School. For the above post the candidate must have a proven track record of success to drive curriculum development, innovative pedagogical practices, holistic education, staff enrichment, team building and leadership qualities with proficiency in computers, effective communication skills and knowledge of NEP.",
  // },

  {
    vacancyType: "Administrative Vacancies",
    positions: ["Admin Executive School Operations and Finance "],
    criteria:
      "M.Com/ MBA with good communication skills, spoken English, computer proficiency and minimum 5 years of experience in Delhi Schools. The candidate should be well-versed with school administration and have good knowledge of Delhi School Education Rules and Act.",
  },

  {
    vacancyType: "Administrative Vacancies",
    positions: ["EA to Principal", "Front Office Executive"],
    criteria:
      "Should be a graduate with a minimum of 5 years of experience in a similar position, with strong communication and computer skills. ",
  },

  // {
  //   vacancyType: "Administrative Vacancies",
  //   positions: ["Admin Executive/ Office Assistant"],
  //   criteria:
  //     "Ideal candidate would be well-versed in UDISE, CBSE and State Government compliances.",
  // },

  {
    vacancyType: "Other Posts",
    positions: ["Sr. Librarian", "Jr. Librarian"],
    criteria: `A minimum of 5 years' experience as a Librarian is required. The candidate needs to have a Bachelors in Library Science, and preferably also a Masters in Library Science.`,
  },

  {
    vacancyType: "Teaching Posts",
    positions: [
      "PRT English",
      "PGT Computer Science",
      "PGT Psychology",
      "PGT Commerce",
      "PGT English",
      "PGT Maths",
      "PGT Computer Science",
      "TGT Computers",
      "TGT English",
      "TGT Social Science",
      "TGT Computer Science",
      "TGT Painting",
      "TGT Maths",
      "School Councellor",
      "Pre Primary Teachers (contractual)",
      "PRT (Computer Science, Maths & all subjects)",
      "Special Educator",
      "Painting (Contractual)",
      "PGT Physical Education on Contract",
      "Art and Crafts Teacher on Contract",
      "Football Coach on Contract",
      "Assistant Pre Primary Teacher",
      "Coaches: Art & Craft, Sports, Chess, Western Music, Western Dance, Theatre",
    ],
    criteria:
      "Minimum 3 years of experience required for all posts. Qualifications as per CBSE norms and CTET requirement. ",
  },

  // {
  //   vacancyType: "Other Posts",
  //   positions: ["IT Support Engineer / (IT Coordinator)"],
  //   criteria: `Should be B.Tech (IT)
  //     / MCA minimum 3 years’ experience,
  //     with good knowledge of multimedia,
  //     web technology and networking.`,
  // },

  // {
  //   vacancyType: "Other Posts",
  //   positions: ["IT Executive (Hardware & Networking)"],
  //   criteria: `<ul>
  //     <li>Primary role is to support and maintain in-house technology equipment and IT assets.
  //     <li> Installing, configuring, diagnosing, repairing, and upgrading all hardware and equipment while ensuring its optimal performance.</li>
  //     <li>Develop and maintain an inventory of all IT equipment’s ( Like Monitors, Keyboards, Modems, Switches, Printers, Scanners, Interactive Panels, CCTV) and other peripheral equipment.</li>
  //     <li> Fulfill Information technology needs of an organization including supervising subordinates, coordinating software implementation and upgrades</li>
  //     </ul>

  //     <h4>Desired Candidate Profile</h1>
  //     <ul>
  //     <li>3-5 years of IT Support Experience.</li>
  //     <li>Hands on Experience in School ERP Management.</li>
  //     <li>Good Practical experience in network management and help desk support.</li>
  //     <li>Good Understanding of TCP/IP Protocols and LAN and WAN Configuration.</li>
  //     <li>Technical expertise in CCTV, Access Control, Intercoms systems.</li>
  //     <li>Monitoring the operation of security equipment (CCTV) for malfunctions or other problems.</li>
  //     </ul>
  //     `,
  // },
];

export default vacanciesData;
